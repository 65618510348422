export enum Routes {
  AdminUsers = "admin-users",
  AdminUsersAuthentication = "admin-users/authentication",
  AdminUsersChangePassword = "admin-users/change-password",
  Dashboard = "dashboard",
  Document = "document",
  Documents = "documents",
  Games = "games",
  LocalNotifications = "local-notifications",
  Recruitments = "recruitments",
  RemoteNotifications = "remote-notifications",
  ReportedUsers = "reported-users",
  Users = "users",
  UsersGetDeleteOtp = "users/get-delete-otp",
}

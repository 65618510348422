import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import GamepadIcon from '@mui/icons-material/Gamepad';
import GroupIcon from "@mui/icons-material/Group";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { Role } from "../../constants";
import { TranslationKey } from "../../i18n";

export interface SidebarItem {
  title: TranslationKey;
  path: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  iconClosed?: boolean;
  iconOpened?: boolean;
  roles?: Role[];
  subNav?: SidebarItem[];
}

export const SidebarData: SidebarItem[] = [
  {
    title: TranslationKey.Dashboard,
    path: "/dashboard",
    icon: QueryStatsIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.AdminUsers,
    path: "/admin-users",
    icon: GroupIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.Documents,
    path: "/documents",
    icon: DocumentScannerIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.Games,
    path: "/games",
    icon: VideogameAssetIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.LocalNotifications,
    path: "/local-notifications",
    icon: NotificationsIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.Recruitments,
    path: "/recruitments",
    icon: GamepadIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.RemoteNotifications,
    path: "/remote-notifications",
    icon: NotificationImportantIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.ReportedUsers,
    path: "/reported-users",
    icon: NoAccountsIcon,
    roles: [Role.Admin],
  },
  {
    title: TranslationKey.Users,
    path: "/users",
    icon: PeopleOutlineIcon,
    roles: [Role.Admin],
  },
];

import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { AdminUserLoginInfo } from "../models";
import { Http } from ".";
import { Role, Routes, Token } from "../constants";

const allowedRoles = (roles: Role[]) => {
  const decodedToken = decodeToken();
  if (!roles) return true;
  for (const role of roles) if (decodedToken?.role === role) return true;
  return false;
};

interface TokenPayload {
  id: string;
  name: string;
  role: number;
  exp: number;
  firstLogin: boolean;
}

const decodeToken = (): TokenPayload | null => {
  const token = localStorage.getItem(Token.name);

  if (token) {
    return jwtDecode(token);
  }

  return null;
};

const getUserId = () => decodeToken()?.id;

const getUserName = () => decodeToken()?.name;

const getUserRole = () => decodeToken()?.role;

const hasRole = (role: Role) => {
  if (decodeToken()?.role === role) return true;

  return false;
};

const loggedIn = () => {
  const decodedToken = decodeToken();

  if (!decodedToken) {
    return false;
  }

  return decodedToken.exp > Date.now().valueOf() / 1000;
};

const login = async (loginInfo: AdminUserLoginInfo) => {
  try {
    const response: any = await Http.put(
      Routes.AdminUsersAuthentication,
      loginInfo
    );

    localStorage.setItem(Token.name, response.token);
    const user = decodeToken();

    if (user?.firstLogin) {
      return "/change-password";
    } else {
      return "/dashboard";
    }
  } catch (error: any) {
    toast.error(error.message);
  }
};

const logout = () => localStorage.removeItem(Token.name);

export const Authentication = {
  allowedRoles,
  getUserId,
  getUserName,
  getUserRole,
  hasRole,
  loggedIn,
  login,
  logout,
};
